// noinspection AllyPlainJsInspection

import '../../../../../legacyjs/custom/vars';
import {CorrelationScatterChartTooltip} from '../../../../components';
import {
    getSelectedLocations,
    getSelectedSubjects,
    getSelectedYears,
    getSubjectTitle,
    getLocationTitle,
    generateObjectForGraph,
    isCitvoiceMeasure, getSelectedSubjectName, getSelectedLocationName,
  getExternalName, getExternalUnit
} from '../../../../actions';
import i18n from "../../../../i18n";
import regression from 'regression';
import Statistics from 'statistics.js';
import getSelectedExternals from "../../../../actions/getSelectedExternals";

const getChartScale = (data) => {
  let maxX = 0.05;
  let maxY = 0.05;

  data.datasets.forEach((value) => {
    const thisMaxX = value.data.map((item) => item.x).reduce((a, b) => Math.max(a, b));
    const thisMaxY = value.data.map((item) => item.y).reduce((a, b) => Math.max(a, b));

    if (thisMaxX > maxX) maxX = Number(thisMaxX);
    if (thisMaxY > maxY) maxY = Number(thisMaxY);
  });

  maxX *= 1.01;
  maxY *= 1.01;

  return {
    y: {
      min: 0,
      max: Math.round(Number.parseFloat(maxY)*10)/10,
    },
    x: {
      min: 0,
      max: Math.round(Number.parseFloat(maxX)*10)/10,
    },
  };
};

const getDataRecord = (state, year, subject, location) => {
  const indexedYml = state.indexedYml;
  const externalData = state.externalData;
  if (subject.type === 'external') {
    return externalData.find((item) => item.yr === year && item.identifier === subject.id && item.iso === location);
  } else {
    return indexedYml[year][subject.id][location];
  }
};

export const getSubjectOrExternalName = (state, subject, with_unit) => {
  if (subject.type === 'external') {
    let name = getExternalName(state, subject.id);
    if (with_unit) {
      name = `${name} (${getExternalUnit(state, subject.id)})`;
    }
    return name;
  } else {
    return getSelectedSubjectName(state, subject.id);
  }
}

export const getSubjectOrExternalIcon = (subject) => {
  if (subject.type === 'external') {
    return 'external_data';
  } else {
    return subject.id;
  }
}

export const getChartTitle = (state) => {
  const subjects = resolveSubjects(state);
  const chartTitle = i18n.t("Correlation {{subject_1}} and {{subject_2}} for {{year}}", {
    'subject_1': `${getSubjectOrExternalName(state, subjects[0])}`,
    'subject_2': `${getSubjectOrExternalName(state, subjects[1])}`,
    'year': resolveYear(),
  });

  return chartTitle;
};

function resolveSubjects(state) {
  const selectedSubjects = getSelectedSubjects(state);
  const selectedExternals = getSelectedExternals(state);
  const subjects = [...selectedSubjects.map((subject) => {
    return {
      id: subject,
      type: 'measure'
    };
  }), ...selectedExternals.map((external) => {
    return {
      id: external,
      type: 'external'
    };
  })];
  return subjects;
}

function resolveYear() {
  const years = getSelectedYears('range1from', 'range1to');
  const selectedYear = years[years.length - 1];
  return selectedYear;
}

export default (state, urlParams) => {
  const selectedYears = getSelectedYears('range1from', 'range1to');
  const selectedYears2 = getSelectedYears('range2from', 'range2to');
  const selectedYear = resolveYear();
  // const selectedLocations = getSelectedLocations(state);
  const selectedLocations = [];
  const allCountriesIso = state?.fullListOfCountries.map((country) => country.iso) || [];
  const collection = [];
  const fullContextCollection = [];
  const notApplicable = 'n/a';


  const dataset = [];
  const labels = [];
  const subjects = resolveSubjects(state);


  const backGroundColor = [];
  const borderColor = [];
  const hoverBackgroundColor = [];

  const missing = [];

  allCountriesIso.forEach((location) => {
      const x = getDataRecord(state, selectedYear, subjects[0], location);
      const y = getDataRecord(state, selectedYear, subjects[1], location);
      if (!x || isNaN(x.v) || x.v < 0) {
        missing.push({ year: selectedYear, subject: subjects[0] });
        return;
      }

      if (!y || isNaN(y.v) || y.v < 0) {
        missing.push({ year: selectedYear, subject: subjects[1] });
        return;
      }

      if (selectedLocations.length > 0 && selectedLocations.indexOf(location) > -1) {
        backGroundColor.push('rgba(14,59,131, 0.5)');
        borderColor.push('rgba(14,59,131, 1)');
        hoverBackgroundColor.push('rgba(14,59,131, 1)');
      } else {
        backGroundColor.push('rgba(52,60,66,0.5)');
        borderColor.push('rgb(52,60,66)');
        hoverBackgroundColor.push('rgb(52,60,66)');
      }

      dataset.push({
          x: x.v,
          y: y.v,
          label: `${getSelectedLocationName(state, location)} ${selectedYear}`,
          x_label: `${getSubjectOrExternalName(state, subjects[0])}`,
          y_label: `${getSubjectOrExternalName(state, subjects[1])}`,
          x_icon: getSubjectOrExternalIcon(subjects[0]),
          y_icon: getSubjectOrExternalIcon(subjects[1]),
      });
  });

  if (!dataset.length) {

    // deduplicate missing
    const filteredMissing = missing.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.year === item.year && t.subject.id === item.subject.id
        ))
    );
    const e = new Error();
    e.filteredMissing = filteredMissing;
    throw e;
  }

  const newItem = {
    type: "scatter",
    backgroundColor: backGroundColor,
    borderColor: borderColor,
    borderWidth: 1,
    data: dataset,
    hitRadius: 9,
    hoverBackgroundColor: hoverBackgroundColor,
    pointHoverRadius: 12,
    pointRadius: 9,
    subject: subjects,
    annotationLine: true
  }

  const cleanData = dataset
    .filter(({ x, y }) => {
      return (
        typeof x === typeof y &&  // filter out one string & one number
        !isNaN(x) &&              // filter out `NaN`
        !isNaN(y) &&
        Math.abs(x) !== Infinity &&
        Math.abs(y) !== Infinity
      );
    })
    .map(({ x, y }) => {
      return [Number(x), Number(y)];             // we need a list of [[x1, y1], [x2, y2], ...]
    });

  const statsData = dataset
    .filter(({ x, y }) => {
      return (
        typeof x === typeof y &&  // filter out one string & one number
        !isNaN(x) &&              // filter out `NaN`
        !isNaN(y) &&
        Math.abs(x) !== Infinity &&
        Math.abs(y) !== Infinity
      );
    })
    .map(({ x, y }) => {
      return {x: Number(x), y: Number(y) };
    });

  const regressionData = regression.linear(cleanData, {precision: 15, order: 2});


  const stats = new Statistics(statsData, {x: 'metric', y: 'metric'});
  const correlation = stats.linearRegression('x', 'y');

  const data = {
    datasets: [newItem],
    maintainAspectRatio: false,
    labels: [
      selectedYear
    ],
  };

  const firstChartScale = getChartScale(data);


  const regressionLine = [];
  for (let i = 0; i < 100; i++) {
    if (i > firstChartScale.x.max || i < firstChartScale.x.min) {
      continue;
    }
    if (regressionData.predict(i)[1] > firstChartScale.y.max || regressionData.predict(i)[1] < firstChartScale.y.min) {
      continue;
    }
    regressionLine.push({
        'x': i,
        'y': regressionData.predict(i)[1],
      // eslint-disable-next-line i18next/no-literal-string
        label: `${selectedYear} r\u00B2 = ${correlation.correlationCoefficient.toFixed(2)}`,
        // x_label: `${getSubjectOrExternalName(state, subjects[0])}`,
        // y_label: `${getSubjectOrExternalName(state, subjects[1])}`,
    });
  }

  const regressionLineDataset = {
      backgroundColor: 'rgba(255,59,131, 0.5)',
      borderColor: 'rgba(255,59,131, 1)',
      borderWidth: 1,
      data: regressionLine,
      hitRadius: 6,
      hoverBackgroundColor: 'rgba(255,59,131, 1)',
      pointHoverRadius: 0,
      pointRadius: 0,
      type: 'line',
      annotationLine: false
  }

  data.datasets.push(regressionLineDataset);
  const chartScale = getChartScale(data);

  const annotationLine = {
    id: 'annotationLine',
    afterDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length && chart.tooltip.dataPoints[0].dataset.annotationLine === true) {
        const { ctx } = chart;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.moveTo(chart.chartArea.left, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.moveTo(activePoint.element.x, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = chart.tooltip.dataPoints[0].dataset.borderColor;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  return {
    data,
    plugins: [annotationLine],
    options: {
      maintainAspectRatio: false,
      responsive: true,
      clip: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 35,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          min: chartScale.x.min,
          max: chartScale.x.max,
          grid: {
            color(context) {
              if (context.tick.value === 0) {
                context.tick.value.toFixed(2);
                return '#000000';
              }
              return 'transparent';
            },
          },
          title: {
            color: 'black',
            display: true,
            text: `${getSubjectOrExternalName(state, subjects[0], true)}`,
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
          },
          ticks: {
            callback: (val) => Number(val).toFixed(1),
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
            color: 'black',
            autoSkip: false,
          },
        },
        y: {
          min: chartScale.y.min,
          max: chartScale.y.max,
          grid: {
            color(context) {
              if (context.tick.value === 0) {
                return '#000000';
              }
              return 'transparent';
            },
          },
          title: {
            display: true,
            text: `${getSubjectOrExternalName(state, subjects[1], true)}`,
            color: 'black',
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
          },
          display: true,
          ticks: {
            callback: (val) => Number(val).toFixed(1),
            color: 'black',
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
              color: 'black',
            },
          },
        },
      },
      interaction: {
        intersect: true,
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            CorrelationScatterChartTooltip({
              state,
              context,
              selectedYear,
            });
          },
        },
      },
    },
  };
};
