// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".exploreWrapper--3VkYh {\n    position: relative;\n}\n\n.exploreBarSelectionError--183QE {\n    position: relative;\n    height: 0;\n    width: 40%;\n    z-index: 140;\n    top: 50px;\n}\n\n[dir=ltr] .exploreBarSelectionError--183QE {\n    left: 30%;\n}\n\n[dir=rtl] .exploreBarSelectionError--183QE {\n    right: 30%;\n}\n\n.exploreBarSelectionErrorInner--3Ihef {\n    color: white;\n    bottom: 20px;\n\n}\n\n[dir] .exploreBarSelectionErrorInner--3Ihef {\n    background-color: #343C42;\n    padding: 10px;\n    border-radius: 10px;\n\n}\n\n[dir] .exploreBarSelectionErrorInner--3Ihef p {\n    margin: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"exploreWrapper": "exploreWrapper--3VkYh",
	"exploreBarSelectionError": "exploreBarSelectionError--183QE",
	"exploreBarSelectionErrorInner": "exploreBarSelectionErrorInner--3Ihef"
};
module.exports = exports;
