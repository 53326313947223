import getUrlQueryParams from '../getUrlQueryParams';

export default (state) => {
  const externals = getUrlQueryParams()
    ?.ext?.split('~')
    ?.filter((external) => external !== '')
    ?.filter((external) => {
      return !state.externalMetrics || state.externalMetrics.find((el) => el.id === external)
    }) || [];


  return externals;
};
