import React, {useEffect, useState} from "react";
import { CorrelationNotice } from "../../components";
import { setCookie, readCookie, getUrlQueryParams} from "../../actions";
import { useAppContext } from "../../context/AppState.jsx";
import { useModalContext } from "../../context/modalContext.jsx";

export default () => {
	const [state, dispatch] = useAppContext();
	const cookie = JSON.parse(readCookie("correlation-info-dismissed"));
	const [shouldShow, setShouldShow] = useState(!cookie);
	const embed = window.location.pathname == '/embed.html';
	const [showModal, closeModal, hasModal] = useModalContext();
	const { view, subview } = getUrlQueryParams();

	if (!shouldShow || cookie) {
		return null;
	}

	if (embed) {
		return null;
	}

	if (!state?.translatedText) {
		return null;
	}

	if (view !== "graph" || subview !== "correlation") {
		return null;
	}

	const dismiss = () => {
		setShouldShow(false);
	}

	return <CorrelationNotice onDismiss={dismiss} />;
};
