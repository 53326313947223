import i18n from "../../i18n";

export default (error) => {
	if (error.length === 0) {
		return [false, [false]];
	}
	if (error.indexOf('noview') !== -1 && error.indexOf('noloc') !== -1 && error.indexOf('nomeas') !== -1) {
		return [i18n.t("Please select a view, a location, and a measure"),
			[
				i18n.t("You must select a view, a location, and a measure."),
			],
			i18n.t("invalid.succint.locviewmeas")
		];
	} else if (error.indexOf('noview') !== -1 && error.indexOf('noloc') !== -1) {
		return [i18n.t("Please select a view and a location"),
			[
				i18n.t("You must select a view and a location."),
			],
			i18n.t("invalid.succint.locview")
		];
	} else if (error.indexOf('noview') !== -1 && error.indexOf('nomeas') !== -1) {
		return [i18n.t("Please select a view and a measure"),
			[
				i18n.t("You must select a view and a measure."),
			],
			i18n.t("invalid.succint.viewmeas")
		];
	} else if (error.indexOf('noloc') !== -1 && error.indexOf('nomeas') !== -1) {
		return [i18n.t("Please select a location and a measure"),
			[
				i18n.t("You must select a location and a measure."),
			],
			i18n.t("invalid.succint.locmeas")
		];
	} else if (error.indexOf('noview') !== -1) {
		return [i18n.t("Please select a view"),
			[
				i18n.t("You must select a view."),
			],
			i18n.t("invalid.succint.view")
		];
	} else if (error.indexOf('noloc') !== -1) {
		return [i18n.t("Please select a location"),
			[
				i18n.t("You must select a location."),
			],
			i18n.t("invalid.succint.loc")
		];
	} else if (error.indexOf('nomeas') !== -1) {
		return [i18n.t("Please select a measure"),
			[
				i18n.t("You must select a measure."),
			],
			i18n.t("invalid.succint.meas")
		];
	} else switch (error[0]) {
		case 'correlation':
			return [i18n.t("Invalid Selection"),
				[
					i18n.t("The correlation view allows you to view the correlation between two measures/external datasets only."),
					i18n.t("Please ensure you have two measures/datasets selected."),
				],
				i18n.t("invalid.succint.correlation")
			];
		case 'toomany':
		default:
			return [ i18n.t("Invalid Selection"),
				[
					i18n.t("The table and graph views allow you to compare one measure across many locations or multiple measures for a single location."),
					i18n.t("You have selected multiple locations and multiple measures"),
					i18n.t("Please change your selection to select a single measure or single location."),
				],
				i18n.t("invalid.succint.toomany")
			];
	}
}
