import { urls } from '../../referenceData';
import lzstring from 'lz-string';

let loadingPromise;

export default async () => {
  if (loadingPromise) {
    await loadingPromise;
  }

  const cacheKey = 'appData-' + process.env.VERSION;
  if (window.localStorage) {
    Object.keys(window.localStorage).forEach((key) => {
      if (key.includes('appData') && key !== cacheKey) {
        window.localStorage.removeItem(key);
      }
    });
  }


  if (window.localStorage && window.localStorage.getItem(cacheKey)) {
    return JSON.parse(lzstring.decompress(window.localStorage.getItem(cacheKey)));
  } else {
    let resolveLoadingPromise;
    loadingPromise = new Promise(function (resolve, reject) {
      resolveLoadingPromise = resolve;
    });
    try {
      const res = await fetch(
        `${urls.apiUrl()}most/?format=json`,// eslint-disable-line i18next/no-literal-string
      );

      const toJson = await res.json();

      window.localStorage && window.localStorage.setItem(cacheKey, lzstring.compress(JSON.stringify(toJson)));
      resolveLoadingPromise(true);

      return toJson;
    } catch (err) {
      console.log(err);
    }
  }
};
