import React, { useEffect } from "react";
import { SiteWideNotice, CookieConsentNotice, Navbar } from "../../reusablePartials";
import { RangeChart, DataTable, SubjectBarGraph, SubjectCorrelationTable } from "./partials";
import { DriversOfChanges } from "../LocationDetailedInfo/partials";
import { getUrlQueryParams } from "../../actions";
import { useAppPipeline } from "../../hooks";
import styles from "./styles.css";
import { useAppContext } from "../../context/AppState.jsx";
import { defaultVariables } from "../../referenceData";
import {useTranslation} from "react-i18next";

const getSelectedSubject = (listOfMeasures) => {
	const addressWithoutFileExtenstion = window.location.pathname.split(".")[0];
	const splitedUrl = addressWithoutFileExtenstion.split("/");

	const selectedSubject = listOfMeasures.find(
		(measure) => measure.s_id.toLowerCase() === splitedUrl[splitedUrl.length - 1].toLowerCase()
	);

	return selectedSubject.s_id;
};

export default () => {
	const [state, dispatch] = useAppContext();
	const urlParams = getUrlQueryParams();
	const { t, i18n } = useTranslation();
	useAppPipeline({ urlParams });


	useEffect(() => {
		let selectedSubject = "";
		if (state.indexedYml) {
			const prepareData = () => {
				const lastYear = defaultVariables.latest_year;
				const firstYear = defaultVariables.earliest_year;
				const measureData = {};
				selectedSubject = getSelectedSubject(state.fullListOfMeasures);

				Object.keys(state.indexedYml).forEach((yr) => {
					measureData[yr] = Object.values(state.indexedYml[yr][selectedSubject])
						.filter((e) => e.v >= 0)
						.sort((e1, e2) => e2.v - e1.v);
				});
				measureData[lastYear] = measureData[lastYear].map((el) => {
					if (el.v === -1 || state.indexedYml[firstYear][el.s_id][el.iso].v === -1) {
						el.change = "n/a";
					} else {
						el.change = (
							Number(el.v).toFixed(1) - Number(state.indexedYml[firstYear][el.s_id][el.iso].v).toFixed(1)
						).toFixed(1);
					}
					return el;
				});
				return measureData;
			};
			dispatch({
				type: "measureData",
				payload: prepareData(),
			});
		}
		dispatch({
			type: "selectedSubject",
			payload: selectedSubject,
		});
		dispatch({
			type: "selectedLocation",
			payload: "g1",
		});
	}, [state.indexedYml, i18n.language]);

	return (
		<>
			<div className="uk-grid uk-grid-small uk-grid-match">
				<div className={`uk-width-1-1@m`}>
					<div className={styles.backgroundWhite}>
						<SubjectBarGraph state={state}/>
					</div>
				</div>
				<div className={`uk-width-2-5@m`}>
					<div className={styles.backgroundWhite}>
						<RangeChart state={state}/>
					</div>
				</div>
				<div className={`uk-width-3-5@m`}>
					<div className={styles.backgroundWhite}>
						<DataTable state={state}/>
					</div>
				</div>
				<div className={`uk-width-1-1@m`}>
					<div className={styles.backgroundWhite}>
						<DriversOfChanges state={state} dispatch={dispatch}/>
					</div>
				</div>
				<div className={`uk-width-1-1@m`}>
					<div className={styles.backgroundWhite}>
						<SubjectCorrelationTable state={state}/>
					</div>
				</div>
			</div>
			<SiteWideNotice/>
			<CookieConsentNotice/>
		</>
	);
};
