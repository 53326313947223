import React from "react";
import styles from "../../styles.css";
import measureGroupBarStyles from "../MeasureGroupBar/styles.css";
import subCategoriesStyles from "../SubCategories/styles.css";
import {isAllChildrensSelected, isSearchedPhrase} from "../../utils";
import {lookupWordByUid} from "../../../../actions";
import {useTranslation} from "react-i18next";


export default ({
	externals,
	selectedExternals,
	onExternalSelect,
					// subject,
					// isSimplifiedView,
					// selectedSubjects,
					// onSubjectSelect,
					// queryParams,
					nestedListsToShow,
					showNestedLists,
					// subjectCounters,
					// selectedGroupsOfSubjects,
					// onSelectAllSubjects,
					// addSelectedSubject,
					// removeSelectedSubject,
					// expandedMeasureBars,
					// setExpandedMeasureBars,
					// searchPhrase,
					// onSubjectChange,
				}) => {

	const { t } = useTranslation();
	return (
		<>
			<div className={styles.measureGroup}>
				<div className={`${styles.measureCollapse} ${styles.measureCollapseSimple}`}>
					<img className={styles.measureIcon} src={`/img/icons/external_data.svg`} alt={t('External Data')}/>
					<h5 className={styles.externalName}>
						<a href="#">
							{t('External Data')}
						</a>
					</h5>
					<div className={measureGroupBarStyles.filler}/>
					{!nestedListsToShow.includes('external') ? (
						<span
							className={`plus uk-icon ${styles.expandIcon} `}
							uk-icon="icon:plus"
							onClick={() => {
								showNestedLists((displayedRows) => [...displayedRows, 'external']);
							}}
						></span>
					) : (
						<span
							className={`minus uk-icon
							${styles.expandIcon}
							
							`}
							uk-icon="icon:minus"
							onClick={() => {
								showNestedLists((displayedRows) => displayedRows.filter((row) => row !== 'external'));
							}}
						></span>
					)}
				</div>
			</div>

			<div
				className={` ${subCategoriesStyles.measureExpand} 
				${!nestedListsToShow.includes('external') ? "uk-hidden" : ""} `}
			>
				<div className="uk-grid-small uk-grid">
					<p className={`${styles.appMenuIntro}`}>{ t("external_data.navigation.info") }</p>
					<ul className={`${styles.externalList} ${subCategoriesStyles.appMenuList}`}>
						{externals.map((external, index) => (
							<li className={`${subCategoriesStyles.listItem} ${styles.externalItem}
							${
								selectedExternals.includes(external.id)
									? ` ${styles.selectedExternal}`
									: " "
							}
							`} key={`external_${index}`}><a
								href={external.id} onClick={(e) => {
									e.preventDefault();
									onExternalSelect(external.id);
							}}>{external.name}</a></li>
						))}
					</ul>
				</div>
			</div>
		</>

	)
	;
};
