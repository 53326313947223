// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .value--G6zv_ {\n    margin-bottom: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"value": "value--G6zv_"
};
module.exports = exports;
