import React, {createContext, useContext, useEffect, useState} from "react";
import {useAppContext} from "./AppState.jsx";

export const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
	const [state] = useAppContext();
	const [Modal, setModal] = useState(false);

	const closeModal = () => {
		setModal(false);
	};

	const showModal = (modal) => {
		setModal(modal);
	};

	const hasModal = () => {
		return Modal;
	};

	useEffect(() => {
		setModal(false);
	}, [state.setUrlQueryParam, state.setDefaultQueryParamsForView]);

	return (
		<ModalContext.Provider value={[showModal, closeModal, hasModal]}>
			{Modal}
			{children}
		</ModalContext.Provider>
	);
};

export const useModalContext = () => {
	return useContext(ModalContext);
};
