import React from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {useModalContext} from "../../context/modalContext.jsx";
import { Tooltip } from 'react-tooltip'
import {invalidErrorMessage} from "../../actions";

export default ({
	showAdvancedSettings,
	radioButtons,
	activeSubview,
	advancedSettingsButton,
	shoudlHideShareButton,
	callback,
	radioButtonOnChange,
	isEmbed,
	optionOnChange,
	subviewOptions,
	activeOption,
}) => {
	const {t} = useTranslation();
	const [showModal] = useModalContext();

	return (<div className="uk-container">
			<div
				className={`${styles.wrapper} ${isEmbed ? "uk-margin-top uk-margin-bottom" : "uk-width-1-1"}`}>
				<div className="idp-footer__content closed">
					<div className={`idp-footer__buttons ${showAdvancedSettings ? 'with-advanced' : 'without-advanced'}`}>
						<div
							className={styles.subviewWrapper}
						>
							{radioButtons.map((radioButton, index) => (
								<div className={`idp-footer__button-wrap
								${!activeSubview && index === 0 ? styles.selectedLeft : ""}
								${activeSubview === radioButton.queryParam ? styles.selectedLeft : ""}
								`}
									 key={`wrapper_${radioButton.text.split(" ").join("")}_${index}`}>
									<label
										key={`${radioButton.text.split(" ").join("")}_${index}`}
										className={`${radioButton.invalid && radioButton.invalid.length ? styles.disabledRadioButton : ""}`}
										data-tooltip-id={`${radioButton.invalid && radioButton.invalid.length ? "settingsheader" : ""}`}
										data-tooltip-content={`${radioButton.invalid && radioButton.invalid.length ? invalidErrorMessage(radioButton.invalid)[2] : ""}`}
									>
										<input
											type="radio"
											className={` uk-radio ${styles.radioButton}`}
											value={radioButton.queryParam}
											name={`subview`}
											checked={activeSubview === radioButton.queryParam}
											onChange={(e) => {
												radioButtonOnChange(e.target.value);
											}}
										/>

										{radioButton.text}
									</label>
									{radioButton.infoContent && (
										<span
											className="uk-margin-small-left"
											uk-icon="icon:info"
											onClick={() => showModal(radioButton.infoContent)}
										/>
									)}
								</div>
							))}
						</div>

						<div
							className={styles.optionsWrapper}
						>
							{subviewOptions.map((option, index) => (
								<label
									key={`${option.text.split(" ").join("")}_${index}`}
									className={`idp-footer__button-wrap ${
										!option.subview.includes(activeSubview) ? styles.disabledRadioButton : ""
									}
								${!activeOption && index === 0 ? styles.selectedRight : ""}
								${activeOption === option.queryParam ? styles.selectedRight : ""}`}
								>
									<input
										disabled={!option.subview.includes(activeSubview)}
										type="radio"
										className={` uk-radio ${styles.radioButton} ${styles.subviewRadioButton}`}
										value={option.queryParam}
										name={`option`}
										checked={activeOption ? activeOption === option.queryParam : index === 0}
										onChange={(e) => {
											optionOnChange(e.target.value);
										}}
									/>
									{option.icon && <img className={styles.subviewIcon} src={`/img/icons/${option.icon}`} alt={option.text} />}
									{option.text}
								</label>
							))}
						</div>

						<div className="idp-footer__share-advanced-wrap">
							{!shoudlHideShareButton ? (
								<button className="uk-button uk-button-default idp-footer__share-button" type="button" onClick={() => callback()}>
									<span className={` ${styles.shareButtonIcon}`} uk-icon="icon:social"/>

									<span>{t("Save/share")}</span>
								</button>
							) : null}

							{!advancedSettingsButton.shouldShow ? (

									<button className="idp-footer__advanced-button" onClick={() => advancedSettingsButton.onClick()}>
										{t("Advanced")}
										{showAdvancedSettings ? (
											<span uk-icon="icon:minus" className={`minus ${styles.expandIcon}`}/>
										) : (
											<span uk-icon="icon:plus" className={`plus ${styles.expandIcon}`}/>
										)}
									</button>

							) : null}
						</div>
					</div>
				</div>
			</div>
			<Tooltip id={"settingsheader"} />
		</div>
	);
};
