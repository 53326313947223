import React from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {useModalContext} from "../../context/modalContext.jsx";
import {setCookie} from "../../actions";

export default ({onDismiss}) => {
	const [_, closeModal] = useModalContext();
	const {t} = useTranslation();

	const dismiss = () => {
		closeModal();
		setCookie("correlation-info-dismissed", true, 365);
		onDismiss && onDismiss();
	}

	return (
		<div className="uk-modal uk-open">
			<div className={`uk-modal-body uk-modal-dialog uk-modal-dialog-custom-width ${styles.modalWrapper}`}>
				<button
					className="uk-modal-close-default uk-close-large uk-icon uk-close"
					uk-icon="icon:close"
					type="button"
					onClick={() => dismiss()}
				/>
				<h2 className="no_top_margin" id="lbl_location_info_modal_header">
					{t("About Correlation")}
				</h2>
				<hr/>
				<div className="uk-grid uk-width-1-1">
					<div className="uk-width-1-1">
						<p>
							{t("Correlation is a statistical measure that reveals the extent to which two variables are associated. The correlation coefficient indicates the strength and direction of the relationship between two variables. Correlation simply means that change in one variable corresponds with change in a second variable. It does not mean that one variable leads to change or causes variation in a second variable. This would be causation. Correlation does not equal causation and users are advised to take care when interpreting these statistics.")}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
