import React from "react";
import {getUrlQueryParams, isSelectionInvalid} from "../../../../actions";
import { removeUrlQueryParam, setUrlQueryParam, getDefaultChartSettings } from "../../../../actions";
import { SettingsPanel } from "../../../../components";
import { useAppContext } from "../../../../context/AppState.jsx";
import { defaultVariables } from "../../../../referenceData";
import { AdditionalSettingPanel } from "./subPartials";
import i18n from "../../../../i18n";

const lastyear = defaultVariables.latest_year;
const firstyear = defaultVariables.earliest_year;

const ionRangeSliderSettings = ({
	range1from = defaultVariables.earliest_year,
	range1to = defaultVariables.latest_year,
	range2from = defaultVariables.mid_year,
	range2to = defaultVariables.latest_year,
	dispatch,
}) => ({
	comparison: {
		score: {
			firstSlider: {
				id: "driversscore",
				hide_min_max: true,
				keyboard: true,
				type: "single",
				min: firstyear,
				max: lastyear,
				from: range1to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range1to", value: data.from }),
					});
				},
			},
		},
		rank: {
			firstSlider: {
				id: "driversscore",
				hide_min_max: true,
				keyboard: true,
				type: "single",
				min: firstyear,
				max: lastyear,
				from: range1to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range1to", value: data.from }),
					});
				},
			},
		},
	},
	graph: {
		score: {
			line: {
				firstSlider: {
					id: "graphscoreline",
					hide_min_max: true,
					keyboard: true,
					type: "double",
					min: firstyear,
					max: lastyear,
					from: range1from,
					to: range1to,
					step: 1,
					grid: false,
					prettify_enabled: false,
					skin: "mif",
					onFinish: (data) => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1from", value: data.from }),
						});
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1to", value: data.to }),
						});
					},
				},
			},
			bar: {
				firstSlider: {
					id: "graphscorebar",
					hide_min_max: true,
					keyboard: true,
					type: "single",
					min: firstyear,
					max: lastyear,
					from: range1to,
					step: 1,
					grid: false,
					prettify_enabled: false,
					skin: "mif",
					onFinish: (data) => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1to", value: data.from }),
						});
					},
				},
			},
		},
		absoluteTrend: {
			bar: {
				firstSlider: {
					id: "graphannualAvgTrendscatter",
					hide_min_max: true,
					keyboard: true,
					type: "double",
					min: firstyear,
					max: lastyear,
					from: range1from,
					to: range1to,
					step: 1,
					grid: false,
					prettify_enabled: false,
					skin: "mif",
					onFinish: (data) => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1from", value: data.from }),
						});
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1to", value: data.to }),
						});
					},
				},
			},
		},
		annualAvgTrend: {
			scatter: {
				firstSlider: {
					id: "graphannualAvgTrendscatter",
					hide_min_max: true,
					keyboard: true,
					type: "double",
					min: firstyear,
					max: lastyear,
					from: range1from,
					to: range1to,
					step: 1,
					grid: false,
					prettify_enabled: false,
					skin: "mif",
					onFinish: (data) => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1from", value: data.from }),
						});
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1to", value: data.to }),
						});
					},
				},
			},
			secondSlider: {
				id: "graphannualAvgTrendscatter2",
				hide_min_max: true,
				keyboard: true,
				type: "double",
				min: firstyear,
				max: lastyear,
				from: range2from,
				to: range2to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range2to", value: data.to }),
					});
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range2from", value: data.from }),
					});
				},
			},
		},
		correlation: {
			scatter: {
				firstSlider: {
					id: "graphCorrelationScatter",
					hide_min_max: true,
					keyboard: true,
					type: "single",
					min: firstyear,
					max: lastyear,
					from: range1to,
					step: 1,
					grid: false,
					prettify_enabled: false,
					skin: "mif",
					onFinish: (data) => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1to", value: data.from }),
						});
					},
				},
			},
			line: {
				firstSlider: {
					id: "graphCorrelationLine",
					hide_min_max: true,
					keyboard: true,
					type: "double",
					min: firstyear,
					max: lastyear,
					from: range1from,
					to: range1to,
					step: 1,
					grid: false,
					prettify_enabled: false,
					skin: "mif",
					onFinish: (data) => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1from", value: data.from }),
						});
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "range1to", value: data.to }),
						});
					},
				},
			}
		}
	},
	table: {
		score: {
			firstSlider: {
				id: "tablescore",
				hide_min_max: true,
				keyboard: true,
				type: "double",
				min: firstyear,
				max: lastyear,
				from: range1from,
				to: range1to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					removeUrlQueryParam({ name: "sortBy" });
					removeUrlQueryParam({ name: "sortDir" });
					setUrlQueryParam({ name: "range1from", value: data.from });
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range1to", value: data.to }),
					});
				},
			},
			secondSlider: {
				id: "tablescore2",
				hide_min_max: true,
				keyboard: true,
				type: "double",
				min: firstyear,
				max: lastyear,
				from: range2from,
				to: range2to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					removeUrlQueryParam({ name: "sortBy" });
					removeUrlQueryParam({ name: "sortDir" });
					setUrlQueryParam({ name: "range2from", value: data.from });
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range2to", value: data.to }),
					});
				},
			},
		},
		rank: {
			firstSlider: {
				id: "tablerank",
				hide_min_max: true,
				keyboard: true,
				type: "double",
				min: firstyear,
				max: lastyear,
				from: range1from,
				to: range1to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					removeUrlQueryParam({ name: "sortBy" });
					removeUrlQueryParam({ name: "sortDir" });
					setUrlQueryParam({ name: "range1from", value: data.from });
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range1to", value: data.to }),
					});
				},
			},
		},
		// raw: {
		// 	firstSlider: {
		// 		id: "tableraw",
		// 		hide_min_max: true,
		// 		keyboard: true,
		// 		type: "double",
		// 		min: firstyear,
		// 		max: lastyear,
		// 		from: range1from,
		// 		to: range1to,
		// 		step: 1,
		// 		grid: false,
		// 		prettify_enabled: false,
		// 		skin: "mif",
		// 		onFinish: (data) => {
		// 			removeUrlQueryParam({ name: "sortBy" });
		// 			removeUrlQueryParam({ name: "sortDir" });
		// 			setUrlQueryParam({ name: "range1from", value: data.from });
		// 			dispatch({
		// 				type: "setUrlQueryParam",
		// 				payload: setUrlQueryParam({ name: "range1to", value: data.to }),
		// 			});
		// 		},
		// 	},
		// },
		drivers: {
			firstSlider: {
				id: "driversscore",
				hide_min_max: true,
				keyboard: true,
				type: "double",
				min: firstyear,
				max: lastyear,
				from: range1from,
				to: range1to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					removeUrlQueryParam({ name: "sortBy" });
					removeUrlQueryParam({ name: "sortDir" });
					setUrlQueryParam({ name: "range1from", value: data.from });
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range1to", value: data.to }),
					});
				},
			},
		},
		changes: {
			firstSlider: {
				id: "driversscore",
				hide_min_max: true,
				keyboard: true,
				type: "double",
				min: firstyear,
				max: lastyear,
				from: range1from,
				to: range1to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					removeUrlQueryParam({ name: "sortBy" });
					removeUrlQueryParam({ name: "sortDir" });
					setUrlQueryParam({ name: "range1from", value: data.from });
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range1to", value: data.to }),
					});
				},
			},
		},
	},
	map: {
		score: {
			firstSlider: {
				id: "mapscore",
				hide_min_max: true,
				keyboard: true,
				type: "single",
				min: firstyear,
				max: lastyear,
				from: range1to,
				step: 1,
				grid: false,
				prettify_enabled: false,
				skin: "mif",
				onFinish: (data) => {
					dispatch({
						type: "setUrlQueryParam",
						payload: setUrlQueryParam({ name: "range1to", value: data.from }),
					});
				},
			},
		},
	},
});

export default ({ isEmbed = false }) => {
	const [state, dispatch] = useAppContext();

	if (!state.showAdvancedSettings) {
		return null;
	}

	const urlParams = getUrlQueryParams();
	const { meas, loc, view, subview, range1from, range1to, range2from, range2to, option } = urlParams;

	const errors = isSelectionInvalid(state, getUrlQueryParams());

	if (errors.length > 0) {
		return null;
	}


	// setDefaultUrlQueryParamsForSubview(urlParams);

	if ((view === "map" && subview === "trend") || view === "overview") {
		return null;
	}

	let firstSliderSettings = null;

	if (view === "graph") {
		firstSliderSettings = ionRangeSliderSettings({
			range1from,
			range1to,
			range2from,
			range2to,
			dispatch,
		})[view][subview][typeof option === "undefined" ? "line" : option].firstSlider;
	} else {
		firstSliderSettings = ionRangeSliderSettings({
			range1from,
			range1to,
			range2from,
			range2to,
			dispatch,
		})[view][subview].firstSlider;
	}

	firstSliderSettings.title = i18n.t("Date range:");

	let secondSliderSettings = null;

	if (
		(view === "graph" && subview === "annualAvgTrend") ||
		(view === "table" && subview === "score" && (urlParams?.showAAT === "true" || urlParams?.showAAT === "1"))
	) {
		secondSliderSettings = ionRangeSliderSettings({
			range1from,
			range1to,
			range2from,
			range2to,
			dispatch,
		})[view][subview].secondSlider;
	}

	const resetAdvancedSettings = {
		text: i18n.t("Reset options"),
		onClick: () => {
			const defaultSettings = getDefaultChartSettings(getUrlQueryParams());


			defaultSettings.forEach((param) => {
				let payload = {};
				if (param[Object.keys(param)] === null) {
					payload = removeUrlQueryParam({
						name: Object.keys(param),
					});
				} else {
					payload = setUrlQueryParam({
						name: Object.keys(param),
						value: param[Object.keys(param)],
					});
				}
				dispatch({
					type: "setUrlQueryParam",
					payload: payload,
				});

			});
		},
	};

	return (
		<SettingsPanel
			firstSliderSettings={firstSliderSettings}
			secondSliderSettings={secondSliderSettings}
			isEmbed={isEmbed}
			resetAdvancedSettings={resetAdvancedSettings}
		>
			<AdditionalSettingPanel urlParams={urlParams} dispatch={dispatch} state={state} />
		</SettingsPanel>
	);
};
