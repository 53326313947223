import {getSelectedExternals, getSelectedLocations, getSelectedSubjects} from "../index";

export default (state, urlParams, overrides, ignore_unselected=false) => {
	if (!state?.indexedYml || !state?.descriptors || !state?.externalData) {
		return [];
	}
	
	let meas = getSelectedSubjects(state);
	let loc = getSelectedLocations(state);
	let externals = getSelectedExternals(state);
	let { view, subview, option, showAAT } = urlParams;

	// apply overrides
	if (overrides) {
		meas = overrides.meas || meas;
		loc = overrides.loc || loc;
		externals = overrides.externals || externals;
		view = overrides.view || view;
		subview = overrides.subview || subview;
		option = overrides.option || option;
		showAAT = overrides.showAAT || showAAT;
	}

	let errors = [];

	if (!ignore_unselected && !view) {
		errors.push('noview');
	}

	// this just needs 2 measures/externals
	// no location required
	if (view ==="graph" && subview === "correlation") {
		if ((meas.length + externals.length) !== 2)
		{
			errors.push('correlation');
		}
		return errors;
	}


	if (!ignore_unselected &&  (!loc || loc.length==0)){
		errors.push('noloc');
	}

	if (!ignore_unselected && (!meas || meas.length==0)) {
		errors.push('nomeas');
	}

	// the default/fallback - 1 measure/many locs or 1 loc many measures
	if (meas.length > 1 && loc.length > 1) {
		errors.push('toomany');
	}

	return errors;

}
