// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir=ltr] .value--1OM5r {\n    margin-left: 30px;\n}[dir=rtl] .value--1OM5r {\n    margin-right: 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"value": "value--1OM5r"
};
module.exports = exports;
