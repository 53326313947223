import React from "react";
import { SettingsHeader, SettingsPanel, AppRouter, ScreenshotFrame, ComponentLoader } from "./partials";
import {
	Footer,
	SavedViewsCarousel,
	Navbar,
	ExplorePanel,
	SiteWideNotice,
	CookieConsentNotice,
	MessageBox,
	SubjectPickerMenu,
	LocationPickerMenu,
	ViewPickerMenu,
	Breadcrumb, CorrelationNoticeOnce
} from "../../reusablePartials";
import { getUrlQueryParams } from "../../actions";
import { useAppPipeline } from "../../hooks";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";

const isOnOverview = (urlParams) =>
	urlParams?.view === "overview" &&
	(urlParams?.subview === "absoluteTrends" || urlParams?.subview === "annualAvgTrend");

export default () => {
	const urlParams = getUrlQueryParams();
	useAppPipeline({ urlParams });
	const { t, i18n } = useTranslation();
	if (urlParams.takingss) {
		return (
			<>
				<ScreenshotFrame />
			</>
		);
	} else {
		return (
			<>
				<header>
					<Navbar />
					<Breadcrumb />
				</header>
				<ExplorePanel />
				<div className={isOnOverview(urlParams) ? styles.backgroundOffWhite : styles.backgroundWhite}>
					<SettingsHeader />
					<SettingsPanel />
					<div className="uk-container">
						<AppRouter />
						<ComponentLoader />
					</div>
				</div>
				<SavedViewsCarousel />
				<Footer />
				<SiteWideNotice />
				<CookieConsentNotice />
				<MessageBox />
				<CorrelationNoticeOnce />
				<ScreenshotFrame />
			</>
		);
	}
};
