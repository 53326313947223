import { useEffect } from "react";
import { useAppContext } from "../context/AppState.jsx";
import {
	getAppData,
	getAppSelectedLanguage,
	getCarouselContent, getExternalData,
	getIndexedYmlData, getLocationTitle,
	getMeasures, getSelectedLocationName,
	isCitvoiceMeasure,
} from "../actions";
import { removeUrlQueryParam, retrieveCustomView, setUrlQueryParam } from "../actions";
import i18n from "../i18n";

const createMeasureTreeOrder = (state, measures) => {
	const treeOrder = [];
	let allNonCvSubjectsInLevel;
	let allCvSubjectsInLevel;

	for (let i = 0; i < 5; i++) {
		allNonCvSubjectsInLevel = measures.filter(
			(subject) => subject.lvl === i && !isCitvoiceMeasure(state, subject.s_id)
		);

		allNonCvSubjectsInLevel.forEach((subject) => treeOrder.push(subject.s_id));

		allCvSubjectsInLevel = measures.filter(
			(subject) => subject.lvl+1 === i && isCitvoiceMeasure(state, subject.s_id)
		);

		allCvSubjectsInLevel.forEach((subject) => treeOrder.push(subject.s_id));
	}
	return treeOrder;
};

export default ({ urlParams }) => {
	const [state, dispatch] = useAppContext();
	const { v: savedViewId } = urlParams;

	useEffect(() => {
		if (!state.indexedYml) {
			const run = async () => {
				try {
					const loadData =  getAppData();
					loadData.then((data) => {
						const { countries, descriptors, groups, measures, strings } = data;
						dispatch({
							type: "descriptors",
							payload: descriptors
						});
						dispatch({
							type: "fullListOfCountryGroups",
							payload: groups
						});
						dispatch({
							type: "fullListOfMeasures",
							payload: measures,
							onSuccess: i18n.t("Loading list of subjects"),
						});

						const pretendState = { descriptors};
						countries.sort((a,b) => {
							const nameA = getSelectedLocationName(pretendState, a.iso);
							const nameB = getSelectedLocationName(pretendState, b.iso);
							if (nameA < nameB) return 1;
							if (nameA > nameB) return -1;
							return 0;
						});
						dispatch({
							type: "fullListOfCountries",
							payload: countries,
							onSuccess: i18n.t("Loading list of countries"),
						});

						const translatedText = strings.reduce((acc, string) => {
							return { ...acc, [string.uid]: string };
						}, {});

						dispatch({
							type: "translatedText",
							payload: translatedText,
						});

						const object = { fullListOfMeasures: measures };
						dispatch({
							type: "measureTreeOrder",
							payload: createMeasureTreeOrder(object, measures)
						});


					});

					const loadMeasures =  getMeasures();
					loadMeasures.then((allMeasures) => {

						dispatch({
							type: "allMeasures",
							payload: allMeasures
						});

						const indexedYml = getIndexedYmlData(allMeasures);

						dispatch({
							type: "indexedYml",
							payload: indexedYml
						});
					});

					const carouselPromise = getCarouselContent();
					carouselPromise.then((carouselContent) => {
						dispatch({
							type: "carouselContent",
							payload:carouselContent,
							onSuccess: i18n.t("Loading Saved View"),
						});
					});

					const externalPromise = getExternalData();
					externalPromise.then((externalData) => {
						dispatch({
							type: "externalData",
							payload:externalData,
							onSuccess: i18n.t("Loading external data"),
						});

						const uniqueExternals = externalData
							.map((external) => external.identifier)
							.filter((value, index, self) => self.indexOf(value) === index)
							.sort()
							.map((identifier) => {
								const ourdata = externalData
									.filter((external) => external.identifier === identifier)
									.sort((a, b) => a.yr - b.yr);
								const first_year = ourdata[0].yr;
								const last_year = ourdata[ourdata.length - 1].yr;

								return {
									id: identifier,
									first_year: first_year,
									last_year: last_year,
								}
							});


						dispatch({
							type: "externalMetrics",
							payload: uniqueExternals,
							onSuccess: i18n.t("Loading list of external metrics"),
						});
					});2

					if (typeof savedViewId !== "undefined") {
						const savedView = await retrieveCustomView(savedViewId);
						removeUrlQueryParam({ name: "v" });

						Object.keys(savedView.urlQueryParams).forEach((param) => {
							setUrlQueryParam({ name: param, value: savedView.urlQueryParams[param] });
						});
						dispatch({
							type: "savedView",
							payload: savedView,
							onSuccess: i18n.t("Saved View Loaded"),
						});
					}
				} catch (err) {
					dispatch({ type: "error", result: err });
				}
			};
			run();
		}
	}, []);
};
