import {
  getCarouselContent,
  getUrlQueryParams,
  readCookie,
  setCookie,
} from './actions';
import { recreateCustomViewsSection } from './lib/savedviews';
import i18n from './i18n';
import * as basicLightbox from 'basiclightbox';

const initializeCarousel = async () => {
  const $carousel = global.$('.saved-slider .carousel-inner');
  const $emptySavedItem = global.$(
    '<div class="carousel-item"><h2></h2><h6></h6><a href="#" class="btn btn-outline-primary">' +
    i18n.t('View now') +
    '</a></div>',
  );

  const carouselContent = await getCarouselContent();

  carouselContent.forEach((slide) => {
    const $newItem = $emptySavedItem.clone();
    // eslint-disable-next-line i18next/no-literal-string
    $newItem.find('h2').text(slide[`${i18n.language}_name`]);
    // eslint-disable-next-line i18next/no-literal-string
    $newItem.find('h6').text(slide[`${i18n.language}_description`]);
    $newItem.find('a').attr('href', `app.html?v=${slide.uid}`);
    $carousel.append($newItem);
  });

  $carousel.find('.carousel-item:first').addClass('active');
  global.$('#savedslider-carousel').carousel();

  const $curatedList = global.$('#lstCuratedViews');

  if ($curatedList.length) {
    carouselContent.forEach((slide) => {
      let div = `<a href="app.html?v=${slide.uid}" class="">`;  // eslint-disable-line i18next/no-literal-string
      div += `<h5>${slide[`${i18n.language}_name`]}</h5>`; // eslint-disable-line i18next/no-literal-string
      div += `<p> ${slide[`${i18n.language}_description`]}</p>`;// eslint-disable-line i18next/no-literal-string

      div += '</a>';

      $curatedList.append(div);
    });
  }
};

const acceptCookies = () => {
  Array.from(document.getElementsByClassName('cookiealert')).forEach((e) => e.remove());
  setCookie('acceptCookies', true, 365);
};

const dismissSwn = () => {
  Array.from(document.getElementsByClassName('idp-sitewide-notice')).forEach(
    (e) => e.remove(),
  );
  setCookie('sitewide-notice-dismissed', true, 365);
};

const autoAcceptCookiesDismissSwnForKiosk = () => {
  const url = new URL(window.location);
  const { cookie_accept: cookieAccept, swn_dismiss: swnDismiss } = getUrlQueryParams();

  if (typeof cookieAccept !== 'undefined' && JSON.parse(cookieAccept) === 1) {
    acceptCookies();
  }

  if (typeof swnDismiss !== 'undefined' && JSON.parse(swnDismiss) === 1) {
    dismissSwn();
  }

  if (url.pathname === '/embed.html') {
    if (
      readCookie('acceptCookies')
      || typeof cookieAccept === 'undefined'
      || JSON.parse(cookieAccept) === 1
    ) {
      acceptCookies();
    }
  }
};

const videoToggler = () => {
  global.$('.how-video').addClass('d-none');
  const $target = global.$(
    '#how-accordion .card-header button:not(.collapsed)',
  );
  if ($target.length) {
    global.$(`#${$target.attr('rel')}`).removeClass('d-none');
  } else {
    global.$('#overview_img').removeClass('d-none');
  }
};

const imageToggler = () => {
  global.$('.overview-img').addClass('d-none');
  const $target = global.$(
    '#overview-accordion .card-header button:not(.collapsed)',
  );
  if ($target.length) {
    global.$('#overview-accordion-reset').removeClass('active');
    global.$(`#${$target.attr('rel')}`).removeClass('d-none');
  } else {
    global.$('#overview-accordion-reset').addClass('active');
    global.$('#overview_img').removeClass('d-none');
  }
};

const additionalLayoutCorrections = () => {
  // used in copyright
  global.$('.current_year').text(new Date().getUTCFullYear());

  global.$('#how-accordion').on('hidden.bs.collapse', videoToggler);
  global.$('#how-accordion').on('shown.bs.collapse', videoToggler);

  global.$('#overview-accordion').on('hidden.bs.collapse', imageToggler);
  global.$('#overview-accordion').on('shown.bs.collapse', imageToggler);
  global.$('#overview-accordion-reset').on('click', (e) => {
    e.preventDefault();
    global.$('#overview-accordion .card-header button').addClass('collapsed');
    global.$('#overview-accordion .collapse').removeClass('show');
    imageToggler();
  });

  global.$('.country-menu').on('beforeshow', () => {
    global.$('#subjectMenu').attr('hidden', true);
    global.$('#viewMenu').attr('hidden', true);
  });

  global.$('.view-menu').on('beforeshow', () => {
    global.$('#subjectMenu').attr('hidden', true);
    global.$('#countryMenu').attr('hidden', true);
  });

  global.$('.measure-menu').on('beforeshow', () => {
    global.$('#countryMenu').attr('hidden', true);
    global.$('#viewMenu').attr('hidden', true);
  });
};

const lightboxes = () => {

  // eslint-disable-next-line i18next/no-literal-string
  const lightbox = basicLightbox.create(`
    <div class="lb-iframe-wrap">
        <iframe class="lb-iframe" title="vimeo-player" src="https://player.vimeo.com/video/${i18n.t('about_video')}?h=dbbde8f8cb"  frameborder="0"    allowfullscreen></iframe>
    </div>
    `);

  // document.querySelector('.hero-video-launch')?.addEventListener('click', () => {
  //   lightbox.show();
  // });

}

const menuTouchClickFallbacks = () => {
  document.querySelectorAll('.primary-nav a > span').forEach((e) => {
    e.addEventListener('click', (event) => {
      event.preventDefault();
      // toggle open class to nearest a tag
        e.closest('li').classList.toggle('open');


    });
  });
}

export default async () => {
  autoAcceptCookiesDismissSwnForKiosk();
  menuTouchClickFallbacks();

  if (
    window.location.pathname !== '/data.html'
    && window.location.pathname !== '/embed.html'
  ) {
    await initializeCarousel();
    recreateCustomViewsSection();
    additionalLayoutCorrections();
    lightboxes();

  }
};
