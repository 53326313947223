import setUrlQueryParam from '../setUrlQueryParam';
import removeUrlQueryParam from '../removeUrlQueryParam';
import { defaultVariables } from '../../referenceData';

const defaultUrlQueryParams = {
  graph: {
    score: {
      line: [
        { range1from: defaultVariables.earliest_year },
        { range1to: defaultVariables.latest_year },
      ],
      bar: [
        { range1to: defaultVariables.latest_year },
        { sortBy: 'score' },
        { sortDir: 'des' },
      ],
    },
    absoluteTrend: {
      bar: [
        {
          range1from: defaultVariables.earliest_year,
        },
        {
          range1to: defaultVariables.latest_year,
        },
        { sortBy: 'score' },
        { sortDir: 'des' },
      ],
    },
    annualAvgTrend: {
      scatter: [
        {
          range1from: defaultVariables.earliest_year,
        },
        {
          range1to: defaultVariables.latest_year,
        },
        {
          range2from: defaultVariables.mid_year,
        },
        {
          range2to: defaultVariables.latest_year,
        },
        { showFullContext: false },
      ],
    },
    correlation: {
      scatter: [
        {
          range1from: defaultVariables.earliest_year,
        },
        {
          range1to: defaultVariables.latest_year,
        },
        {
          range2from: defaultVariables.mid_year,
        },
        {
          range2to: defaultVariables.latest_year,
        },
        { showFullContext: false },
      ],
      line: [
        {
          range1from: defaultVariables.earliest_year,
        },
        {
          range1to: defaultVariables.latest_year,
        },
        {
          range1from: defaultVariables.earliest_year,
        },
        {
          range1to: defaultVariables.latest_year,
        },
      ]
    }
  },
  table: {
    score: [
      {
        range1from: defaultVariables.earliest_year,
      },
      {
        range1to: defaultVariables.latest_year,
      },
      {
        range2from: defaultVariables.mid_year,
      },
      {
        range2to: defaultVariables.latest_year,
      },
      { showLowest: true },
      { showHighest: true },
      { showEstimated: true },
      { showTrimmed: true },
      { showTrimmedEstimated: true },
      { showHighlights: true },
      { showFullContext: false },
      { showAAT: false },
      { collapseAll: true },
    ],
    rank: [
      {
        range1from: defaultVariables.earliest_year,
      },
      {
        range1to: defaultVariables.latest_year,
      },
      { collapseAll: true },
      { showLowest: true },
      { showHighest: true },
      { showHighlights: true },
      { showFullContext: false },
    ],
    drivers: [
      {
        range1from: defaultVariables.earliest_year,
      },
      {
        range1to: defaultVariables.latest_year,
      },
    ],
  },
  map: {
    score: [{ range1to: defaultVariables.latest_year }],
  },
};

export default (urlParams) => {
  const { view, subview, option } = urlParams;

  if (
    typeof defaultUrlQueryParams[view] === 'undefined'
    || typeof defaultUrlQueryParams[view][subview] === 'undefined'
  ) {
    return;
  }

  if (view === 'graph') {
    return defaultUrlQueryParams[view][subview][
      typeof option === 'undefined' ? Object.keys(defaultUrlQueryParams[view][subview])[0] : option
    ].forEach((param) => {
      if (typeof urlParams[Object.keys(param)] === 'undefined') {
        setUrlQueryParam({
          name: Object.keys(param),
          value: param[Object.keys(param)],
        });
      }
    });
  }

  const allowedParams = ['meas', 'loc', 'view', 'subview'];

  Object.keys(urlParams).forEach((param) => {
    if (!allowedParams.includes(param)) {
      removeUrlQueryParam({ name: param });
    }
  });

  return defaultUrlQueryParams[view][subview].forEach((param) => {
    if (typeof urlParams[Object.keys(param)] === 'undefined') {
      setUrlQueryParam({
        name: Object.keys(param),
        value: param[Object.keys(param)],
      });
    }
  });
};
