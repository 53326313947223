import React, { useState } from "react";
import styles from "./styles.css";

export default ({ child, close }) => {

	return (
		<div className="info-menu">
			<i
				className="close"
				uk-icon="icon: close; ratio: 2"
				onClick={close}
			/>
			<div
				className={`${styles.tabChildrenFrame}`}
				// key={`subMenu_${index}`}
			>
				{child}
			</div>
		</div>
	);
};
