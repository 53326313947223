import React from "react";
import { ExplorePanel } from "../../components";
import { useAppContext } from "../../context/AppState.jsx";
import {
	getExternalName,
	getSelectedExternals,
	invalidErrorMessage,
	isSelectionInvalid,
	setMenuToOpen
} from "../../actions";
import {
	getSelectedLocations,
	getSelectedSubjects,
	getUrlQueryParams,
	getSelectedLocationName,
	getSelectedSubjectName,
} from "../../actions";
import i18n from "../../i18n";

const toggleMenu = (activeMenu, menuToOpen, dispatch) => {
	if (activeMenu !== menuToOpen) {
		dispatch({
			type: "setMenuToOpen",
			payload: setMenuToOpen(menuToOpen),
		});
	} else {
		dispatch({
			type: "setMenuToOpen",
			payload: setMenuToOpen(""),
		});
	}
};

const getNavigationButtonParams = () => {
	if (window.location.pathname === "/data.html") {
		return false;
	}
	return { label: "GO", onClick: () => alert("dziala") };
};

const getMeasureLabel = (state, selectedItems, selectedExternals) => {
	const totalLength = selectedItems.length + selectedExternals.length;
	if (totalLength === 0 || !state?.indexedYml) {
		return i18n.t("Measure");
	}
	if (totalLength === 1) {
		if (selectedItems.length > 0) {
			return getSelectedSubjectName(state, selectedItems[0]);
		} else {
			return getExternalName(state, selectedExternals[0]);
		}
	}
	if (totalLength > 1) {
		return i18n.t("{{count}} Measures", { count: totalLength });
	}
};

const getLocationLabel = (state, selectedItems) => {
	if (selectedItems.length === 0 || !state?.indexedYml) {
		return i18n.t("Location");
	}
	if (selectedItems.length === 1) {
		return getSelectedLocationName(state, selectedItems[0]);
	}
	if (selectedItems.length > 1) {
		return i18n.t("{{count}} Locations", { count: selectedItems.length });
	}
};

export default () => {
	const [state, dispatch] = useAppContext();

	const selectedLocations = getSelectedLocations(state);
	const selectedSubjects = state?.fullListOfMeasures ? getSelectedSubjects(state) : [];
	const selectedExternals = state?.externalMetrics ? getSelectedExternals(state) : [];

	const errors = isSelectionInvalid(state, getUrlQueryParams(), [], true);
	const errorMessage = invalidErrorMessage(errors)[2];

	const { view } = getUrlQueryParams();
	const ucfirstView = view ? view.charAt(0).toUpperCase() + view.slice(1).toLowerCase() : null;

	const title = i18n.t("Explore the data");
	const dropdownButtons = [
		{
			label: getMeasureLabel(state, selectedSubjects, selectedExternals),
			onClick: () => toggleMenu(state?.setMenuToOpen, "measure", dispatch),
		},
		{
			label: getLocationLabel(state, selectedLocations),
			onClick: () => toggleMenu(state?.setMenuToOpen, "location", dispatch),
		},
		{
			label: view ? i18n.t(ucfirstView) : i18n.t("View"),
			onClick: () => toggleMenu(state?.setMenuToOpen, "view", dispatch),
		},
	];

	return (
		<ExplorePanel title={title} dropdownButtons={dropdownButtons} navigationButton={getNavigationButtonParams()} errorMessage={errorMessage} />
	);
};
