import React from "react";
import styles from "./styles.css";

export default ({ items }) => {
	return (
		<div className={`app-menu sub-menu generic-info-menu ${styles.genericSubmenuWrapper} `}>
			<div className="uk-container icon-container"></div>
			<div className="uk-container">
				<div className="generic-info-menu-inner">
					<ul className="generic-info-menu-list">
						{items.map((item, index) => (
							<li key={`item_${index}`} className="generic-info-menu-list-item">
								<a className="generic-info-menu-link"  href={item.navigateTo}>
									{item.label}<br />
									<img  className="generic-info-menu-image" src={item.image}
										 alt={item.label}/>
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};
