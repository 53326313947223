import React, { useMemo } from "react";
import {Chartjs, CorrelationNotice, TooManySelectionsPlaceholder} from "../../../../components";
import { default as getChartConfig, getChartTitle, getSubjectOrExternalName } from "./utils";
import {
	getSelectedExternals,
	getSelectedLocations,
	getSelectedSubjects,
	setDefaultUrlQueryParamsForSubview
} from "../../../../actions";


export default ({ state, urlParams, isTakingScreenshot }) => {
	if (!state?.indexedYml || !state?.externalData) {
		return null;
	}

	if (
		typeof urlParams.range1from === "undefined" ||
		typeof urlParams.range1to === "undefined" ||
		typeof urlParams.range2from === "undefined" ||
		typeof urlParams.range2to === "undefined"
	) {
		setDefaultUrlQueryParamsForSubview(urlParams);
	}

	const chartTitle = useMemo(() => getChartTitle(state), [state.setUrlQueryParam, i18n.language]);
	const chartSize = useMemo(
		() => ({
			height: "745px",
		}),
		[state.setUrlQueryParam]
	);


	let chartConfig;
	try {
		chartConfig = useMemo(() => getChartConfig(state, urlParams), [state.setUrlQueryParam, i18n.language]);
	} catch (e) {
		const missing = e.filteredMissing;
		const title = i18n.t('Invalid Selection');
		const description = [
			i18n.t("We do not have data available for the measures/external datasets and year that you have selected."),
			i18n.t("Data is missing for: {{missing}}", {'missing': missing.map((m) => {
					return `${getSubjectOrExternalName(state, m.subject, false)} ${m.year}`;
				}).join(", ")}),
		];

		return <TooManySelectionsPlaceholder title={title} description={description} />;
	}


	const chartId = isTakingScreenshot ? "screenshotBarChart" : "scatter-chart";

	return (
		<Chartjs
			chartTitle={chartTitle}
			chartConfig={chartConfig}
			chartId={chartId}
			chartSize={chartSize}
			isTakingScreenshot={isTakingScreenshot}
		/>
	);

};
