import React, { useState } from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {defaultVariables} from "../../referenceData";
import {useModalContext} from "../../context/modalContext.jsx";

export default ({title, titleModal, tableData, valueHeadings, type, tableClass = ''}) => {
	const [activeTab, setTab] = useState(0);
	const [shouldShowAllMeasures, setAllMeasuresVisibility] = useState(true);
	const {t} = useTranslation();
	const [sortedTableRows, sortTableRows] = useState(tableData);
	const [showModal] = useModalContext();

	return (
		<div className="tab-wrap">
			{title && <h3 className={`uk-text-center ${styles.dataTableTitle}`} >
				{title}
				{titleModal && (
					<span
						className="uk-margin-small-left"
						uk-icon="icon:info"
						onClick={() => showModal(titleModal)}
					/>
				)}
			</h3>}
			<ul className="nav nav-tabs" id="myTab" role="tablist">
				{tableData.map((tab, index) => (
					<li
						key={`${index}`}
						className={`uk-width-1-${tableData.length} nav-item measures-tab`}
						role="presentation"
						onClick={() => {
							setTab(index);
						}}
					>
						<a
							className={` ${activeTab === index ? styles.tabButtonActive : styles.tabButton}`}
							id="ttscores-tab"
							data-toggle="tab"
							href="#ttscores"
							role="tab"
							aria-controls="ttscores"
							aria-selected="true"
						>
							{tab.text}
						</a>
					</li>
				))}
			</ul>
			{tableData[activeTab].filters ? (
				<div
					className={`uk-margin uk-container-expand uk-text-center uk-background-muted ${styles.filterWrapper}`}
				>
					{tableData[activeTab].filters.map((filter, index) => (
						<label
							key={`radioButtons_${index}`}
							onClick={() => filter.onClick()}
							className={styles.radioButtonLabel}
						>
							<input className={`uk-radio ${styles.radioButton}`} type="radio" checked={filter.checked} />
							{filter.name}
						</label>
					))}
				</div>
			) : null}

			<div className="tab-content" id="topTenTabContent">
				<div className="tab-pane fade show active" id="ttscores" role="tabpanel" aria-labelledby="ttscores-tab">
					<div className="uk-grid uk-grid-small">
						{tableData[activeTab].tables.map((table, index) => (
							<div className={`uk-width-1-${tableData[activeTab].tables.length}@s top-ten`} key={`tt-table-${index}`}>
								<h4 className={styles.tableHeadline}>{!table.titleInTable ? table.title : ''}</h4>
								<div className={`top-ten-table ${tableClass}`}>
									<div className="top-ten-table-header">{table.titleInTable ? table.title : ''}</div>
									{ valueHeadings.map((heading, index) => (
										<div key={`valueHeading_${index}`} className="top-ten-table-header">
											{heading.map((item, index) => {
												if (index === 0) {
													return <>{item}</>
												}
												return <><br/>{item}</>
											})}
										</div>
									)) }
									{table.rows.map((row, rowIndex) => {
										return row.columns.map((column, columnIndex) => {
											return columnIndex === 0 ? (
												<div
													key={`tableCell_${rowIndex}_${columnIndex}`}
													className={`
												${`fromjs top-ten-row-title ${row.props.class}`}
												${column.props.class}
												${!column.props.hasIcon ? "with-disc" : "with-icon"}
												`}
													style={column.props.styles}
												>
													<span a={type} className={type === 'location' ? "subject-name" : ""}>{column.value}</span>
												</div>
											) : (
												<div
													key={`tableCell_${rowIndex}_${columnIndex}`}
													className={`fromjs ${column.props.class}`}
												>
													{column.value}
												</div>
											);
										});
									})}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
