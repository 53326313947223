import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import {idpevent} from "../../lib/event";
import {NavbarDropdownFrame} from "../index";
export default ({ logo, navigationItems }) => {
	const [showChildren, setChildren] = useState("");
	const [t, i18n] = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		idpevent.fire("idp.changelanguage");
	}

	const activeLang = i18n.language;

	const expand = (e) => {
		// jQuery('#navbarCollapse').toggle()
		e.preventDefault();
		return false;
	}

	const closeSub = (e) => {
		setChildren("");
	}

	return (
		<div className="uk-container">
			<nav className="navbar navbar-expand-lg primary-nav ">
				<div className="lang-switcher">
					<ul>
						<li className={activeLang == 'en' ? 'active' : '' } dir="ltr"><a onClick={() =>changeLanguage('en')} href="#">English</a></li>
						<li className={activeLang == 'fr' ? 'active' : ''} dir="ltr"><a onClick={() => changeLanguage('fr')} href="#">Français</a></li>
						<li className={activeLang == 'pt' ? 'active' : ''} dir="ltr"><a onClick={() => changeLanguage('pt')} href="#">Português</a></li>
						<li className={activeLang == 'ar' ? 'active' : ''} dir="rtl"><a onClick={() => changeLanguage('ar')} href="#">العربية</a></li>
					</ul>
				</div>
				<a className="navbar-brand" href={logo.navigateTo}>
					<img src={logo.src} alt={logo.alt} />
				</a>
				{/* Mobile navigation */}
				<button
					className="navbar-toggler collapsed"
					type="button"
					data-toggle="collapse"
					data-target="#navbarCollapse"
					aria-controls="navbarCollapse"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon">
						<span />
						<span />
						<span />
						<span />
					</span>
				</button>
				<div className="collapse navbar-collapse" id="navbarCollapse">
					<ul className="navbar-nav mr-auto justify-content-end w-100">
						{navigationItems.map((nav, index) => (
							<li
								key={`${nav.label.split(" ").join("")}_${index}`}
								className={`nav-item4 ${nav.liClass} ${nav.isActive ? "active" : ""}`}
								data-menu={nav.label}
							>
								<a
									onClick={(e) => {
										if (nav.hasChildren) {
											e.preventDefault();
											if (showChildren !== nav.name) {
												setChildren(nav.name);
											} else {
												setChildren("");
											}
										}
									}}
									className={`nav-link`}
									href={nav.navigateTo}
									target={nav.target ? nav.target : "_self"}
								>
									{nav.label}
									{nav.hasChildren || nav.inlineChildren ? <span uk-icon="chevron-down" onClick={ e => expand(e) } /> : null}
								</a>

								{nav.inlineChildren ?
									<ul className="dropdown-menu">
										{nav.inlineChildren.map((child, index) => (
											<li key={`${child.label.split(" ").join("")}_${index}`} className="nav-item">
												<a
												className="nav-link"
												href={child.navigateTo}
												target={child.target ? child.target : "_self"}
												>
													{child.label}
												</a>
											</li>
										) )}
									</ul>
								: null }


							</li>
						))}
					</ul>
				</div>
				{showChildren ? <><NavbarDropdownFrame child={navigationItems.find((nav) => nav.name === showChildren).hasChildren} close={closeSub} /></> : null}
			</nav>
		</div>
	);
};
