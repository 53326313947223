import { defaultVariables, urls } from '../../referenceData';
import lzstring from 'lz-string';

let loadingPromise;

export default async () => {
  if (loadingPromise) {
    await loadingPromise;
  }
  const cacheKey = 'measureData-' + process.env.VERSION;
  if (window.localStorage) {
    Object.keys(window.localStorage).forEach((key) => {
      if (key.includes('measureData') && key !== cacheKey) {
        window.localStorage.removeItem(key);
      }
    });
  }

  if (window.localStorage && window.localStorage.getItem(cacheKey)) {
    return JSON.parse(lzstring.decompress(window.localStorage.getItem(cacheKey)));
  } else {
    let resolveLoadingPromise;
    loadingPromise = new Promise(function (resolve, reject) {
      resolveLoadingPromise = resolve;
    });
    try {
      const level1 =  fetch(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&to_level=1`,// eslint-disable-line i18next/no-literal-string
      );

      const level2 =  fetch(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&from_level=2&to_level=2`,// eslint-disable-line i18next/no-literal-string
      );

      const level3 =  fetch(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&from_level=3&to_level=3`,// eslint-disable-line i18next/no-literal-string
      );

      const level4 =  fetch(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&from_level=4&to_level=4`,// eslint-disable-line i18next/no-literal-string
      );
      const allData = await Promise.all([level1, level2, level3, level4]);
      const flattened = (await Promise.all(allData.map( async (data) => {
        const toJson = (await data.json()).data;
        return toJson;
      }))).flat();
      window.localStorage && localStorage.setItem(cacheKey, lzstring.compress(JSON.stringify(flattened)));
      resolveLoadingPromise(true);
      return flattened;

    } catch (err) {
      console.log(err);
    }
  }
};
